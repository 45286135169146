/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-08-11 09:53:53
 * @LastEditTime: 2022-08-12 19:03:34
 * @Descripttion: file content
 */
import React from 'react'
import { getConf } from '@/proConf'
import privacy from '@/doc/privacy'
import './index.scss'
// import { useNavigate } from 'react-router-dom'

interface privacyitem {
	d?: string
	c?: string
	t?: string
	n?: Array<privacyitem>
}

const product = getConf().product

function Privacy ({ switchPage, isPay }: any) {
	// const navigate = useNavigate()
	// const goback = () => {
	// 	navigate(-2)
	// }
	const parseArr = (arr: Array<privacyitem>) => {
		return arr
			.map((item: privacyitem, idx) => {
				return Parse(item)
			})
			.join('')
	}
	const Parse = (item: privacyitem): string => {
		if (item.t === 'table') {
			return item.d as string
		} else {
			const tag = item.t ? item.t : 'p'
			const cls = item.c ? item.c : ''
			return `<${tag} class="${cls}">
                ${item.n
									? item.n
											.map((item: privacyitem, idx) => {
												return Parse(item)
											})
											.join('')
									: item.d}
            </${tag}>`
		}
	}
	return (
		<div className="privacy" style={{ display: !isPay ? 'block' : 'none' }}>
			<div className="header newline">
				<span onClick={() => switchPage()} className="goback iconfont">
					&#xe80b;
				</span>
				<p className="tit">XFAI助手学习卡购买及服务协议</p>
			</div>
			<div dangerouslySetInnerHTML={{ __html: parseArr(privacy[product]) }} />
		</div>
	)
}

export default Privacy
