/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import './index.scss'
// import { useSearchParams } from 'react-router-dom'
import { useDownload } from '@/hooks/useDownload'

function Download () {
    // const [search] = useSearchParams()
    // const id = search.get('id')
    // console.log('xxx', id)

    const isWeixin = (window.navigator.userAgent.includes('MicroMessenger'))
    const [showTip, setShowTip] = useState(isWeixin)

    // function goDownload () {
    //     window.open('https://apps.apple.com/us/app/xfai%E5%8A%A9%E6%89%8B/id1634146930')
    // }
    return (
        <div className='Download'>
            <div className='Download-border-padding'>
                <div className='Download-border'>
                    <div className='Download-appName'>
                        <img src={require('@/assets/app-icon.png')}></img>
                        <span>XFAI助手</span>
                    </div>
                    <div className='Download-appInfo'>英语听说教学AI助手</div>
                    <div className='Download-button' onClick={useDownload}>点击下载</div>
                </div>
            </div>

            { showTip &&
                <div className='Download-tip-background'>
                    <img className='Download-tip' src={require('@/assets/download-tip.png')} onClick={() => setShowTip(false)}/>
                </div>
            }
        </div>
    )
}

export default Download
