/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-07-20 09:52:09
 * @LastEditTime: 2022-08-08 14:58:17
 * @Descripttion: file content
 */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import './utils/flexible'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
