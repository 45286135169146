import { getConf } from '@/proConf'

console.log(getConf())
const appName = getConf().name
const urlConf = {
	iphone: 'https://itunes.apple.com/us/app/yi-ke-bang/id900395043?l=zh&ls=1&mt=8',
	other: 'https://cdn-resource.ekwing.com/ekwing/client/Android_p_3.5.0.apk',
	huawei: `appmarket://details?id=${appName}`,
	oppo: `oppomarket://details?packagename=${appName}`,
	vivo: `vivomarket://details?id=${appName}`,
	xiaomi: `mimarket://details?id=${appName}`
}
/**
 * 是否为内置浏览器
 * @returns
 */
function is_neizhi () {
	const ua = navigator.userAgent.toLowerCase()
	if (ua.includes('MicroMessenger')) {
		return 'weixin'
	} else if (ua.includes('qq')) {
		return 'QQ'
	} else if (ua.includes('alipay')) {
		return 'alipay'
	}
	return false
}

/**
 * 判断手机品牌
 * @returns
 */
function verifyBrand () {
	const userAgent = navigator.userAgent.toLowerCase()
	const isIphone = userAgent.match(/(iphone|ipad|ipod)/i)
	const isHuawei = userAgent.match(/huawei/i)
	const isHonor = userAgent.match(/honor/i)
	const isOppo = userAgent.match(/oppo/i)
	const isOppoR15 = userAgent.match(/PACM00/i)
	const isVivo = userAgent.match(/vivo/i)
	const isXiaomi = userAgent.match(/mi\s/i)
	const isXIAOMI = userAgent.match(/xiaomi/i)
	const isXiaomi2s = userAgent.match(/mix\s/i)
	const isRedmi = userAgent.match(/redmi/i)

	if (isIphone) {
		return 'iphone'
	} else if (isHuawei || isHonor) {
		return 'huawei'
	} else if (isOppo || isOppoR15) {
		return 'oppo'
	} else if (isVivo) {
		return 'vivo'
	} else if (isXiaomi || isRedmi || isXiaomi2s || isXIAOMI) {
		return 'xiaomi'
	} else {
		return 'other'
	}
}
/**
 * 跳转下载的主方法,如果是内置浏览器,需要跳转到手机自带的浏览器方可使用
 * @returns
 */
export function useDownload () {
	if (!is_neizhi()) window.location.href = urlConf[verifyBrand()]
}
