import React, { useState, useEffect } from 'react'
import './index.scss'
import servce from '@api/index'
import { getParams } from '@/utils/tools'
import { getConf } from '@/proConf'

const wx = require('weixin-js-sdk')

const dealInfo = ['订单信息', '订单号：', '卡片名：', '有效期：']
const studentInfo = ['学生信息', '姓名：', '学校：', '班级：']
const appid = getConf().appid
const product = getConf().product

const initData = {
	amount: 0,
	deal: ['', '', '', ''],
	student: ['', '', '', ''],
	remain_time: 0
}
const orderCondition = [
	{
		status: 'waitForPay',
		text: '支付剩余时间'
	},
	{
		status: 'paying',
		text: '订单支付中'
	},
	{
		status: 'success',
		text: '支付成功'
	},
	{
		status: 'overTime',
		text: '订单超时，如需购买请重新发起'
	},
	{
		status: 'notSupport',
		text: '该学习卡暂不支持购买'
	}
]

const WXParams = {
	appId: '',
	timeStamp: 0, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
	nonceStr: '', // 支付签名随机串，不长于 32 位
	package: '', // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
	signType: '', // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
	paySign: '' // 支付签名
}

function formatTime (time: number) {
	const hour = Math.floor(time / 3600)
	const hourStr = hour < 10 ? '0' + hour : hour
	const minute = Math.floor((time % 3600) / 60)
	const minuteStr = minute < 10 ? '0' + minute : minute
	const second = time % 60
	const secondStr = second < 10 ? '0' + second : second
	return hourStr + ':' + minuteStr + ':' + secondStr
}

function Pay ({ switchPage, isPay }: any) {
	const [selected, setSelected] = useState(false)
	const [orderStatus, setOrderStatus] = useState(0)
	const [showDialog] = useState(false)
	const [orderSn, setOrderSn] = useState('')
	const [payLink, setPayLink] = useState('')
	const [openid, setOpenid] = useState('')
	const [wxParams, setWxParams] = useState(WXParams)
	const [info, setInfo] = useState(initData)
	const [channel, setChannel] = useState('wechat') as any
	const [second, setSecond] = useState(info.remain_time)

	const checkStatus = (code: number | undefined) => {
		switch (code) {
			case 0: // 等待支付
				return 0
			case 1: // 待支付
				return 0
			case 2: // 已支付
				return 2
			case 3: // 已完成
				return 2
			case 4: // 已取消
				return 4
			case 5: // 已关闭
				return 4
			case 2020101: // 分享过期
				return 3
			case 2020102: // 商品不存在
				return 4
			case 4410401: // 商品不存在
				return 4
			case 4420302: // 该学习卡暂不支持购买
				return 4
			case 4420301: // 商品不存在或已下架
				return 4
			default:
				return 0
		}
	}
	const getProjectParams = (search?: string) => {
		const uid = parseInt(getParams('uid', search) as string)
		const goodsId = parseInt(getParams('goods_id', search) as string)
		const orderId = parseInt(getParams('order_id', search) as string)
		const shareTime = parseInt(getParams('share_time', search) as string)
		const to = getParams('to', search) as string
		return { uid, goods_id: goodsId, order_id: orderId, share_time: shareTime, to }
	}

	useEffect(() => {
		const order_sn = getParams('out_trade_no') as string
		if (order_sn) {
			getOrderInfo(order_sn)
			return
		}
		const to = getParams('to') as string
		setChannel(to)
		if (to === 'alipay') {
			// 支付宝支付
			checkInfo()
		} else if (to === 'wechat') {
			// 微信支付
			window.location.href =
				'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
				appid +
				'&redirect_uri=https%3A%2F%2Fpay.xunfeiketang.cn&response_type=code&scope=snsapi_base&state=' +
				encodeURIComponent(window.location.search) +
				'#wechat_redirect'
		} else {
			// 获取openid
			servce
				.getOpenid({ code: getParams('code') as string, appid })
				.then((res: any) => {
					setOpenid(res.openid)
					checkInfo(getProjectParams(getParams('state') as string))
					setOrderStatus(checkStatus(res.code))
				})
				.catch((res) => {
					setOrderStatus(checkStatus(res.code))
				})
		}
	}, [])

	// 校验信息
	const checkInfo = (
		params?: { uid: number; goods_id: number; order_id: number; share_time: number; to: string } | undefined
	) => {
		const { uid, goods_id, order_id, share_time, to } = params || getProjectParams()
		servce
			.checkShare({ uid, goods_id, order_id, share_time })
			.then((res: any) => {
				setChannel(to)
				if (res.order_sn) {
					getOrderInfo(res.order_sn)
					setOrderSn(res.order_sn)
				} else {
					buyGoods({ uid, to, goods_id })
				}
				setOrderStatus(checkStatus(res.code))
			})
			.catch((err) => {
				setOrderStatus(checkStatus(err.code))
			})
	}

	const getOrderInfo = (order_sn: string) => {
		servce.getOrderInfo(order_sn).then((res: any) => {
			setInfo({
				amount: res.order_info.price,
				deal: [
					'',
					res.order_info.order_sn,
					res.order_info.order_title,
					Math.trunc(res.order_info.valid_time / (24 * 3600)) + '天'
				],
				student: ['', res.student_info.student_name, res.student_info.school_name, res.student_info.class_info],
				remain_time: res.order_info.status
			})
			const nowDate = new Date().getTime()
			if (res.order_info.end_time * 1000 - nowDate < 0) {
				setOrderStatus(3)
				return
			}
			setSecond(Math.floor(res.order_info.end_time - nowDate / 1000))
			setOrderStatus(checkStatus(res.order_info.order_status))
		})
	}

	// 下单
	const buyGoods = (check: { uid: number; to: any; goods_id: number }) => {
		servce
			.buy({
				uid: check.uid,
				pay_type: check.to,
				product,
				order_goods: [
					{
						goods_id: check.goods_id,
						total: 1
					}
				]
			})
			.then((res: any) => {
				getOrderInfo(res.order_sn)
				setOrderSn(res.order_sn)
			})
			.catch((err) => {
				setOrderStatus(checkStatus(err.code))
			})
	}
	// 发起支付
	const placeOrder = () => {
		servce
			.placeOrder({
				order_sn: orderSn,
				pay_type: channel,
				openid,
				gateway: channel === 'wechat' ? 'wechat.js' : 'alipay.wap'
			})
			.then((res: any) => {
				if (channel === 'wechat') {
					setWxParams(res.data)
					// const { timeStamp, appId, nonceStr, signType, paySign } = res.data
					console.log(JSON.stringify(res.data.timeStamp))
					wx.config({
						debug: false,
						appId: res.data.appId,
						timestamp: res.data.timeStamp,
						nonceStr: res.data.nonceStr,
						package: res.data.package,
						signType: res.data.signType,
						paySign: res.data.paySign,
						jsApiList: ['chooseWXPay']
					})
				} else {
					setPayLink(res.data)
				}
			})
	}

	useEffect(
		() => {
			if (payLink || wxParams.appId !== '') {
				nowBuy()
			}
		},
		[payLink, wxParams]
	)

	const nowBuy = async () => {
		if (!selected) {
			alert('请先阅读并同意《购买及售后服务协议》后方可购买')
			return
		}
		setOrderStatus(1)
		if (channel === 'wechat') {
			wx.ready(function () {
				wx.chooseWXPay({
					appId: wxParams.appId,
					timestamp: wxParams.timeStamp,
					nonceStr: wxParams.nonceStr,
					package: wxParams.package,
					signType: wxParams.signType,
					paySign: wxParams.paySign,
					success: () => {
						servce.getQuery({ order_sn: orderSn, channel, gateway: 'wechat.js' }).then(() => setOrderStatus(2))
					}
				})
			})
		} else {
			window.location.href = payLink
			servce.getQuery({ order_sn: orderSn, channel, gateway: 'alipay.wap' }).then(() => setOrderStatus(2))
		}
	}

	// 定时器
	useEffect(
		() => {
			if (orderStatus === 0 && orderSn) {
				const timer = setInterval(() => {
					// 闭包里捕获的second永远在初始值，所以要在setSecond里获得实时值
					setSecond((pre: number) => {
						if (pre === 1 || pre <= 0) {
							clearInterval(timer)
						}
						return pre - 1
					})
				}, 1000)
			}
		},
		[orderStatus, orderSn]
	)

	return (
		<div className="OrderPay" style={{ display: isPay ? 'block' : 'none' }}>
			{/* <div>{wxres}</div> */}
			<div className="OrderPay-content">
				<div className="OrderPay-content-title">订单支付</div>
				<img className="OrderPay-content-avator" src={require('@assets/logo.png')} alt="" />
				<div className="OrderPay-halfTop">
					{orderStatus !== 2 && <div className="OrderPay-halfTop-const">待支付金额</div>}
					{orderStatus === 2 && (
						<div className={'OrderPay-halfTop-' + orderCondition[orderStatus].status}>
							{orderCondition[orderStatus].text}
						</div>
					)}
					<div className="OrderPay-halfTop-money">
						<span className="OrderPay-halfTop-yuan">¥</span>
						<span className="OrderPay-halfTop-amount">{info.amount}</span>
					</div>
					{orderStatus !== 2 && (
						<div className={'OrderPay-halfTop-' + orderCondition[orderStatus].status}>
							{orderCondition[orderStatus].text}
							{orderStatus === 0 && <span>{formatTime(second)}</span>}
						</div>
					)}
				</div>
				<div className="OrderPay-seperate">
					<div className="OrderPay-seperate-left" />
					<img src={require('@/assets/seperate.png')} alt="" />
					<div className="OrderPay-seperate-right" />
				</div>
				<div className="OrderPay-halfBottom">
					<div className="OrderPay-halfBottom-deal">
						{orderStatus !== 4 &&
							dealInfo.map((item, index) => {
								return (
									<div
										className="OrderPay-halfBottom-infoLine"
										style={{
											fontWeight: index === 0 ? 700 : 400,
											color: index !== 0 ? '#5E7284' : ''
										}}
										key={item}
									>
										{item}
										<span>{info.deal[index]}</span>
									</div>
								)
							})}
					</div>
					<div className="OrderPay-halfBottom-student">
						{orderStatus !== 4 &&
							studentInfo.map((item, index) => {
								return (
									<div
										className="OrderPay-halfBottom-infoLine"
										style={{
											fontWeight: index === 0 ? 600 : 400,
											color: index !== 0 ? '#5E7284' : ''
										}}
										key={item}
									>
										{item}
										<span>{info.student[index]}</span>
									</div>
								)
							})}
					</div>
				</div>
				{orderStatus === 0 && (
					<div className="bottom">
						<button onClick={placeOrder} className="OrderPay-content-payButton">
							立即支付
						</button>
						<div className="OrderPay-content-agreement">
							<img
								className="OrderPay-content-agreement-radio"
								src={selected ? require('@/assets/radio_selected.png') : require('@/assets/radio.png')}
								onClick={() => setSelected(!selected)}
							/>
							同意
							<span className={'OrderPay-content-agreement-link'} style={{ fontWeight: 600 }} onClick={() => switchPage()}>
								《购买及售后服务协议》
							</span>
						</div>
					</div>
				)}
			</div>
			{/* 弹窗经后续沟通，不需要 */}
			{showDialog && (
				<div className="OrderPay-dialog-div">
					<div className="OrderPay-dialog">
						<div className="OrderPay-dialog-title">支付提示</div>
						<div className="OrderPay-dialog-content">您有一笔未完成的支付订单，可继续支付～</div>
						<div style={{ display: 'flex' }}>
							<button className="OrderPay-dialog-restartButton">重新发起</button>
							<button className="OrderPay-dialog-continueButton" onClick={nowBuy}>
								继续支付
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Pay
