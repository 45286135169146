/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-08-08 17:19:45
 * @LastEditTime: 2022-08-11 09:53:07
 * @Descripttion: file content
 */

import { PRODUCT } from './typings/apiType'

const conf = {
	changchun: {
		name: 'XFAI助手',
		appid: 'wxfb25ff189be24c5a',
		product: 'changchun'
	}
}

export function getConf (): { name: string; appid: string, product:PRODUCT } {
	return conf[PRODUCTION]
}
