/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-07-20 14:48:24
 * @LastEditTime: 2022-08-08 15:22:24
 * @Descripttion: file content
 */

import { BuyPramas, CheckSharePramas, OpenidPramas, PlayOrderParams, QueryParam } from '@/typings/apiType'
import { $get, $post } from '@api/http'

// 轮询获取支付状态
function getQuery (queryParams:QueryParam) {
	return new Promise((resolve) => {
		$get('/order/query')(queryParams)
		.then((res) => resolve(res))
		.catch(() => {
			setTimeout(() => {
				getQuery(queryParams)
			}, 2000)
		})
	})
}

// 获取获取订单详情
async function getOrderInfo (order_sn:string) {
    return await $get('/order/getOrderInfo')({ order_sn })
}
// 校验信息
async function checkShare (checkSharePrams:CheckSharePramas) {
    return await $get('/cart/checkShare')(checkSharePrams)
	}
	// 下单
	async function buy (buyPrams:BuyPramas) {
		return await $post('/cart/buy')(buyPrams)
	}
	// 支付订单
	async function placeOrder (playOrderParams:PlayOrderParams) {
		return await $post('/pay/payorder')(playOrderParams)
	}
	async function getOpenid (openidPramas:OpenidPramas) {
		return await $get('/wechat/oauth')(openidPramas)
	}

export default {
	getQuery,
	buy,
	checkShare,
	placeOrder,
	getOrderInfo,
	getOpenid
}
