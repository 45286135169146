/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-08-11 10:01:05
 * @LastEditTime: 2022-08-11 10:07:46
 * @Descripttion: file content
 */

import { changchun } from './changchun'

export default {
	changchun
}
